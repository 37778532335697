/**
 * Mediquo events
 */
export const MediquoEvent = Object.freeze({
  /**
   * Flutter events
   * @description planned to deprecate in the future in favor of `native` events
   */
  flutter: {
    close: 'mediquo_flutter_sdk_close',
    download: 'mediquo_flutter_sdk_download',
    lifecycleChanged: 'mediquo_flutter_sdk_lifecycle_changed',
  },

  /**
   * Native events
   */
  native: {
    close: 'mediquo_native_close',
    download: 'mediquo_native_download',
    lifecycleChanged: 'mediquo_native_lifecycle_changed',
  },

  /**
   * Cordova events
   */
  cordova: {
    open: 'open_cordova',
  },

  /**
   * Internal events
   * @description Use it for internal communication between projects
   */
  internal: {
    videocallNativeClose: 'videocall_native_close',
    videocallProfileVerify: 'videocall_profile_verify',
    videocallProfileForm: 'videocall_profile_form',
    videocallWaiting: 'videocall_waiting',
    videocallJoined: 'videocall_joined',
    videocallCanceled: 'videocall_canceled',
    videocallTriageWaiting: 'videocall_triage_waiting',
    videocallTriageNext: 'videocall_triage_next',
    videocallShowChat: 'videocall_show_chat',
    videocallLeave: 'videocall_leave',
    videocallError: 'videocall_error',
    videocallPermissions: 'videocall_permissions',

    nativeClose: 'native_close',
    nativeReload: 'native_reload',
    nativeDownload: 'native_download',
    nativeToggleVideo: 'native_toggle_video',
    nativeConsultations: 'native_consultations',

    consultations: 'consultations',
    consultationAssigned: 'consultation_assigned',
    consultationFinished: 'consultation_finished',
    consultationSkipped: 'consultation_skipped',

    navigate: 'navigate',
    widgetOpened: 'widget_opened',
    widgetClosed: 'widget_closed',
    callJoined: 'call_joined',
    returnVideocall: 'return_videocall',
  },
});
