export const MediquoAdapter = Object.freeze({
  cordova: 'cordova',
  flutter: 'flutter',
  native: 'native',
  web: 'web',
});

export type AdapterType = (typeof MediquoAdapter)[keyof typeof MediquoAdapter];

export const isCordova = (value: string): value is 'cordova' => {
  return value === MediquoAdapter.cordova;
};

export const isFlutter = (value: string): value is 'flutter' => {
  return value === MediquoAdapter.flutter;
};

export const isNative = (value: string): value is 'native' => {
  return value === MediquoAdapter.native;
};

export const isWeb = (value: string): value is 'web' => {
  return value === MediquoAdapter.web;
};

export const isFlutterOrNative = (
  value: string,
): value is 'flutter' | 'native' => {
  return isFlutter(value) || isNative(value);
};
