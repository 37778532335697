import classNames from 'classnames';
import React, { FC } from 'react';
import Alert from '../../assets/icons/AlertIcon';

export interface Props {
  name: string;
  register: any;
  value: any;
  errors: any;
}

export interface Nested {
  Link: FC<LinkProps>;
}
interface LinkMouseEvent extends React.MouseEvent<HTMLElement> {}

const CheckBox: FC<Props> & Nested = ({
  name,
  register,
  value,
  errors,
  children,
}) => {
  return (
    <label className="flex pb-2 text-sm cursor-pointer">
      <input
        type="checkbox"
        name={name}
        {...register}
        value={name}
        className="hidden"
      />
      <span
        className={classNames(
          'w-4 h-4 flex flex-shrink-0 transition-all items-center justify-center border rounded focus:outline-none mr-2',
          {
            'bg-primary border-none': value && !errors,
            'bg-white border-gray-medium': !value && !errors,
            'border-negative bg-white': !!errors,
          },
        )}
      >
        {!!value && (
          <svg
            width="24"
            height="9"
            viewBox="0 0 12 9"
            fill="none"
            className="text-white"
          >
            <path
              d="M10.0417 0.19332L3.455 6.77999L1.135 4.46665C0.875 4.20665 0.455 4.20665 0.195 4.46665C-0.065 4.72665 -0.065 5.14665 0.195 5.40665L2.98167 8.19332C3.24167 8.45332 3.66167 8.45332 3.92167 8.19332L10.9817 1.13999C11.2417 0.879987 11.2417 0.459987 10.9817 0.199987H10.975C10.7217 -0.0666799 10.3017 -0.0666799 10.0417 0.19332Z"
              fill="currentColor"
            />
          </svg>
        )}
      </span>
      {children}
      {errors && <Alert className="w-4 h-4 ml-2 text-red" />}
    </label>
  );
};

interface LinkProps {
  href: string | any;
  apiKey?: string;
}

const Link: FC<LinkProps> = ({ children, href }) => {
  const handleClick = (event?: LinkMouseEvent) => {
    event?.preventDefault();
    return window.open(href, '_blank');
  };

  return (
    <button
      onClick={(event?: LinkMouseEvent) => handleClick(event)}
      className="font-medium text-primary mx-1"
    >
      {children}
    </button>
  );
};

CheckBox.Link = Link;
export default CheckBox;
