import { useEffect, useRef, useState } from 'react';
import callConnected from '../../assets/audio/call_connected.mp3';
import { MediquoEvent } from '../../lib/mediquo-events';

export default function useWidget() {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isPendingMessage, setIsPendingMessage] = useState(false);
  const toneRef = useRef(new Audio(callConnected));
  const [isVideoCallHidden, setIsVideoCallHidden] = useState(false);

  const toggleChat = (professional_hash) => {
    if (isChatOpen) {
      window.MediquoWidget.close();
    } else {
      window.MediquoWidget?.navigate(
        `/professionals/${professional_hash}/room`,
      );
    }
    setIsChatOpen((prev) => !prev);
  };

  const hideVideoCall = (professional_hash) => {
    setIsVideoCallHidden(true);
    window.parent.postMessage(
      {
        command: MediquoEvent.internal.navigate,
        payload: { path: `professionals/${professional_hash}/room` },
      },
      '*',
    );
  };

  const exitVideoCall = () => {
    setIsVideoCallHidden(true);
    window.parent.postMessage(
      {
        command: MediquoEvent.internal.navigate,
        payload: { path: `professionals` },
      },
      '*',
    );
  };

  const toggleAppointment = (id) => {
    isChatOpen
      ? window.MediquoWidget.close()
      : window.MediquoWidget.navigate(`/appointments/${id}`);
    setIsChatOpen((prev) => !prev);
  };

  const onMessage = ({ data }) => {
    switch (data.command) {
      case MediquoEvent.internal.nativeConsultations:
      case MediquoEvent.internal.consultations:
        setIsPendingMessage(data.payload?.pendingConsultations > 0);
        break;
      case MediquoEvent.internal.widgetOpened:
        setIsChatOpen(true);
        break;
      case MediquoEvent.internal.widgetClosed:
        setIsChatOpen(false);
        break;
      case MediquoEvent.internal.callJoined:
        toneRef.current.play().catch();
        break;
      case MediquoEvent.internal.returnVideocall:
        setIsVideoCallHidden(false);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    window.addEventListener('message', onMessage);
  }, []);

  return {
    isPendingMessage,
    toggleChat,
    toggleAppointment,
    hideVideoCall,
    isVideoCallHidden,
    exitVideoCall,
  };
}
