import { useNavigate, useParams } from 'react-router';
import { SubmitHandler, useForm, useFormState } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import DocumentLens from '../../../assets/illustrations/DocumentLens';
import { MediQuoAPI } from '../../../api';
import CheckBox from '../../../components/Form/CheckBox';
import Button from '../../../components/Button';
import { Namespace } from '../../../i18n/namespaces';
import HeaderView from '../../../components/HeaderView';
import { useState } from 'react';

const Terms = ({ isUpdate, profile }: any) => {
  const { apiKey, jwt, type } = useParams();
  const { t } = useTranslation(Namespace.Terms);
  const navigate = useNavigate();
  const { register, control, watch, handleSubmit } = useForm<any>({
    mode: 'onChange',
  });
  const { errors } = useFormState({ control });
  const [legal_consent, commercial_accepted] = watch([
    'legal_consent',
    'commercial_accepted',
  ]);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit: SubmitHandler<any> = ({
    legal_consent,
    commercial_accepted = false,
  }) => {
    setIsLoading(true);
    if (legal_consent) {
      MediQuoAPI.acceptLegalConsent({
        terms: !!legal_consent,
        privacy: !!legal_consent,
        commercial_accepted: !!commercial_accepted,
        jwt: jwt!,
        apiKey: apiKey!,
      }).then(() => {
        setTimeout(() => {
          navigate(0);
        }, 1500);
      });
    }
    setIsLoading(false);
  };

  return (
    <div className="bg-background">
      <div className="relative max-h-screen h-screen p-6 grid max-w-[800px] mx-auto">
        <HeaderView
          isWithLineSeparator={false}
          showLogo={type !== 'i'}
          withLanguageSelector
        />
        <div className="flex flex-col h-full justify-around p-4 py-6">
          <div className="flex flex-col justify-center items-center text-gray-dark py-[30px]">
            <DocumentLens />
            <div className="text-xl text-center justify-center font-medium mt-12">
              {t(`${!!isUpdate ? 'update' : 'new'}.title`)}
            </div>
            <div className="flex text-xs text-center justify-center mt-3 mx-10">
              {t(`${!!isUpdate ? 'update' : 'new'}.subtitle`)}
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="p-6 pb-5 bg-bg rounded-xl mb-[30px]">
              <CheckBox
                register={register('legal_consent', { required: true })}
                name="legal_consent"
                value={legal_consent}
                errors={errors?.legal_consent}
              >
                <small>
                  <DefaultLegalConsentText
                    privacyUrl={profile?.legal_consent?.privacy_url}
                    termsUrl={profile?.legal_consent?.terms_url}
                    name={profile?.legal_consent?.organization_name}
                  />
                </small>
              </CheckBox>
              {profile?.legal_consent?.has_commercial_communications && (
                <CheckBox
                  register={register('commercial_accepted')}
                  name="commercial_accepted"
                  value={commercial_accepted}
                  errors={errors?.commercial_accepted}
                >
                  <div className="text-xs">
                    {t('checklist.commercial', {
                      organization: profile?.legal_consent?.organization_name,
                    })}
                  </div>
                </CheckBox>
              )}
            </div>
            <Button type="submit" disabled={!legal_consent || isLoading}>
              {t('button')}
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

const DefaultLegalConsentText = ({
  privacyUrl,
  termsUrl,
  name,
}: {
  privacyUrl?: string;
  termsUrl?: string;
  name?: string;
}) => {
  return (
    <div className="text-xs">
      <Trans translate={Namespace.Terms} i18nKey={'checklist.privacy'}>
        He leído y acepto la
        <CheckBox.Link href={privacyUrl ?? 'link'}>
          política de privacidad
        </CheckBox.Link>
      </Trans>
      <Trans translate={Namespace.Terms} i18nKey="checklist.terms">
        y los
        <CheckBox.Link href={termsUrl ?? 'link'}>
          términos y condiciones
        </CheckBox.Link>
        de {name ?? 'mediQuo'}
      </Trans>
    </div>
  );
};

export default Terms;
