export default (props: any) => (
  <svg
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.0334 22.5998L16.3193 17.8857L11.6053 22.5998C11.0867 23.1183 10.2382 23.1183 9.71967 22.5998C9.20113 22.0812 9.20113 21.2327 9.71967 20.7142L14.4337 16.0001L9.71967 11.2861C9.20113 10.7675 9.20113 9.91899 9.71967 9.40044C10.2382 8.8819 11.0867 8.8819 11.6053 9.40044L16.3193 14.1145L21.0334 9.40044C21.5519 8.8819 22.4005 8.8819 22.919 9.40044C23.4375 9.91899 23.4375 10.7675 22.919 11.2861L18.205 16.0001L22.919 20.7142C23.4375 21.2327 23.4375 22.0812 22.919 22.5998C22.4005 23.1183 21.5519 23.1183 21.0334 22.5998Z"
      fill="currentColor"
    />
  </svg>
);
