export default (props: any) => (
  <svg
    width="164"
    height="164"
    viewBox="0 0 164 164"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="164" height="164" rx="82" fill="#B5B5B5" />
    <rect x="39" y="31" width="85" height="102" rx="4" fill="white" />
    <path d="M51 50H76" stroke="#979797" />
    <path d="M51 63H82" stroke="#979797" />
    <path d="M51 76H99" stroke="#979797" />
    <path d="M51 89H113" stroke="#979797" />
    <path d="M51 102H113" stroke="#979797" />
    <path d="M51 115H113" stroke="#979797" />
    <path
      d="M114 42.9999C122.284 51.2841 122.284 64.7156 114 72.9999C105.716 81.2841 92.2843 81.2841 84 72.9999C75.7157 64.7156 75.7157 51.2841 84 42.9999C92.2843 34.7156 105.716 34.7156 114 42.9999ZM87.6 69.3999C93.896 75.6959 104.104 75.6959 110.4 69.3999C116.696 63.1038 116.696 52.8959 110.4 46.5999C104.104 40.3038 93.896 40.3038 87.6 46.5999C81.304 52.8959 81.304 63.1038 87.6 69.3999Z"
      fill="black"
    />
    <rect
      x="88.7681"
      y="47.7681"
      width="20"
      height="20"
      rx="10"
      fill="#979797"
    />
    <path
      d="M103.335 54.336L96.2968 61.3737L93.8179 58.9019C93.5401 58.6241 93.0914 58.6241 92.8136 58.9019C92.5358 59.1797 92.5358 59.6285 92.8136 59.9063L95.7911 62.8838C96.0689 63.1616 96.5176 63.1616 96.7954 62.8838L104.339 55.3474C104.617 55.0696 104.617 54.6209 104.339 54.3431H104.332C104.061 54.0581 103.612 54.0581 103.335 54.336Z"
      fill="white"
    />
    <rect
      x="110.191"
      y="72.9331"
      width="6"
      height="25"
      transform="rotate(-45 110.191 72.9331)"
      fill="black"
    />
  </svg>
);
