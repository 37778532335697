import { useEffect } from 'react';
import { startErrorLog } from './utils/handlingErrors';
import 'dayjs/locale/es';
import './i18n';
import RoutesIndex from './routes';
import { ToastContainer } from 'react-toastify';
import './react-toastify.css';

function App() {
  useEffect(() => {
    startErrorLog();
  });

  return (
    <>
      <RoutesIndex />
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
      />
    </>
  );
}

export default App;
