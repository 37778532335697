import { Routes, Route } from 'react-router-dom';
import Consultation from '../pages/Consultation';
import Home from '../pages/Home';
import Authorized from '../containers/AuthorizedRoute';
import Waiting from '../pages/Appointment/Waiting';
import Reporting from '../pages/Appointment/Reporting';
import Documentation from '../pages/Appointment/Documentation';
import ProfileVerify from '../pages/Appointment/ProfileVerify';
import Appointment from '../pages/Appointment';
import AuthorizedAppointment from '../containers/Appointment/AuthorizedAppointment';
import Login from '../pages/Login';
import { useEffect } from 'react';
import { startErrorLog } from '../utils/handlingErrors';
import ErrorView from '../pages/Error';
import DeclinedView from '../pages/Appointment/Declined';
import Expired from '../containers/Appointment/Expired/Expired';
import 'dayjs/locale/es';
import '../i18n';

function RoutesIndex() {
  useEffect(() => {
    startErrorLog();
  });

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route
        path="/c/:id/:apiKey"
        element={
          <Authorized type="consultations">
            <Consultation />
          </Authorized>
        }
      />

      <Route path="/:type/:id/:apiKey">
        <Route path="" element={<Login type="appointments" />} />
      </Route>

      <Route path="/:type/:id/:apiKey/:jwt">
        <Route
          path=""
          element={
            <AuthorizedAppointment>
              <Appointment />
            </AuthorizedAppointment>
          }
        />

        <Route path="profile/:hash/verify" element={<ProfileVerify />} />
        <Route path="waiting" element={<Waiting />} />
        <Route path="declined" element={<DeclinedView />} />
        <Route path="expired" element={<Expired />} />
        <Route path="reporting" element={<Reporting />} />
        <Route path="documentation" element={<Documentation />} />
      </Route>
      <Route path="*" element={<ErrorView />} />
    </Routes>
  );
}

export default RoutesIndex;
