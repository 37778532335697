import { useAppointment } from '../../../containers/Appointment/useAppointment';
import { AppointmentStates } from '../../../utils/states';
import Loading from '../../Loading';
import ErrorView from '../../Error';
import { WaitingContainer } from '../../../containers/Appointment/Waiting';
import getAppointmentState from '../../../utils/getAppointmentState';
import { usePatient } from '../../../containers/Appointment/usePatient';
import Triage from '../Triage';
import { useNavigate, useParams } from 'react-router-dom';
import Reporting from '../Reporting';
import { MediquoEvent } from '../../../lib/mediquo-events';

const Waiting = () => {
  const { id, apiKey, jwt, type } = useParams();
  const data = useAppointment();
  const {} = usePatient();
  const navigate = useNavigate();

  const stateStatus = getAppointmentState(data);

  switch (getAppointmentState(data)) {
    case AppointmentStates.errorNotAvailable:
    case AppointmentStates.errorExpired:
    case AppointmentStates.errorGeneric:
      if (['i'].includes(type)) {
        window.parent.postMessage(
          { command: MediquoEvent.internal.videocallError },
          '*',
        );
      }
      const { error } = data;
      const state =
        error?.status === 400
          ? 'notAvailable'
          : error?.status === 401
            ? 'expired'
            : 'generic';
      return <ErrorView state={state} />;
    case AppointmentStates.loading:
      return <Loading />;
    case AppointmentStates.triage:
      return <Triage />;
    case AppointmentStates.declined:
      navigate(`/${type}/${id}/${apiKey}/${jwt}/declined`);
    case AppointmentStates.notTimeYet:
    case AppointmentStates.waitingRoom:
      return <WaitingContainer state={stateStatus} data={data} />;
    case AppointmentStates.finished:
    case AppointmentStates.reporting:
      if (['i'].includes(type)) {
        return <Reporting />;
      }
      data.navigateDocumentation();
    default:
      return <ErrorView />;
  }
};
export default Waiting;
