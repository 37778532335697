import axios from 'axios';
import { config } from '../config';
import { getSession } from '../utils/useSession';
import TrackerSdk from '../utils/TrackingSdk';
import * as Sentry from '@sentry/browser';

type SetupRQ = {
  apiKey: string;
  appointmentId?: string;
};

type AutoAuthenticateRQ = {
  apiKey: string;
  jwt: string;
  id: string;
};

export type UpdateProfileRQ = {
  apiKey: string;
  jwt: string;
  formData: string;
};

type AuthenticateRQ = {
  id: string;
  apiKey: string;
  code: string;
  type: string;
};

type ConsultationRQ = {
  id: string;
  apiKey: string;
  jwt?: string;
};

type CallRQ = {
  id: string;
  apiKey: string;
  callId: string;
  jwt: string;
};

type DocumentationRQ = {
  id: string;
  apiKey: string;
  type: string;
  jwt: string;
};

type DownloadRQ = {
  id: string;
  url: string;
  jwt: string;
};

type WaitingRoom = {
  id: string;
  professionalHash: string;
  apiKey: string;
  jwt: string;
};

enum ConsultationType {
  appointment = 'appointment',
  meet = 'meet',
}

type AcceptLegalConsent = {
  terms: boolean;
  privacy: boolean;
  commercial_accepted?: boolean;
  jwt: string;
  apiKey: string;
};

export const MediQuoAPI = {
  setup: ({ apiKey, appointmentId }: SetupRQ) =>
    axios
      .get(
        `${config.services.mediquo.sdk_api_url}/v1/setup?appointment_id=${appointmentId}`,
        {
          headers: {
            'x-api-key': apiKey!,
          },
        },
      )
      .catch((error) =>
        Sentry.captureException(error, {
          extra: { setup: 'setup theme error' },
        }),
      ),
  autoLogin: ({ apiKey, jwt, id }: AutoAuthenticateRQ) =>
    axios
      .get(`${config.services.mediquo.sdk_api_url}/v1/profile`, {
        headers: {
          'x-api-key': apiKey!,
          Authorization: `Bearer ${jwt!}`,
        },
      })
      .catch((error) => {
        const trackerData = {
          consultationId: id,
          consultationType: 'appointment',
          callId: null,
          userHash: null,
          apiKey: apiKey,
        };
        TrackerSdk.setConsultation(trackerData);
        TrackerSdk.event('autologin error');
        return error.response;
      }),
  acceptLegalConsent: ({
    terms,
    privacy,
    commercial_accepted,
    jwt,
    apiKey,
  }: AcceptLegalConsent) =>
    axios
      .post(
        `${config.services.mediquo.chat_api_url}/profile/v1/legal-consent`,
        {
          terms_accepted: terms,
          privacy_accepted: privacy,
          commercial_accepted,
        },
        {
          headers: {
            'x-api-key': apiKey,
            Authorization: `Bearer ${jwt}`,
          },
        },
      )
      .catch((error) =>
        Sentry.captureException(error, {
          extra: { terms, privacy, commercial_accepted },
        }),
      ),
  updateProfile: ({ apiKey, jwt, formData }: UpdateProfileRQ) =>
    axios
      .put(`${config.services.mediquo.sdk_api_url}/v1/profile`, formData, {
        headers: {
          'x-api-key': apiKey!,
          Authorization: `Bearer ${jwt!}`,
        },
      })
      .catch((error) =>
        Sentry.captureException(error, { extra: { formData } }),
      ),
  authenticate: ({ id, apiKey, code, type }: AuthenticateRQ) =>
    axios
      .post(
        `${config.services.mediquo.sdk_api_url}/v1/${type}/${id}/authenticate`,
        { code },
        {
          headers: {
            'x-api-key': apiKey!,
          },
        },
      )
      .catch((error) =>
        Sentry.captureException(error, { extra: { id, code, type } }),
      ),

  forgotCode: ({ id, apiKey }: ConsultationRQ) =>
    axios
      .post(
        `${config.services.mediquo.sdk_api_url}/v1/consultations/${id}/forgot-code`,
        {},
        {
          headers: {
            'x-api-key': apiKey!,
          },
        },
      )
      .catch((error) => {
        console.log(error, 'forgotCode');
      }),

  appointments: {
    get: ({ id, apiKey, jwt }: { id: string; apiKey: string; jwt: string }) =>
      axios
        .get(`${config.services.mediquo.sdk_api_url}/v1/appointments/${id}`, {
          headers: {
            'x-api-key': apiKey,
            Authorization: `Bearer ${jwt ?? getSession({ id })}`,
          },
        })
        .catch((error) => {
          return error.response;
        }),
  },
  calls: {
    get: ({ id, apiKey, callId, jwt }: CallRQ) =>
      axios
        .get(`${config.services.mediquo.sdk_api_url}/v1/calls/${callId}/join`, {
          headers: {
            'x-api-key': apiKey,
            Authorization: `Bearer ${jwt ?? getSession({ id })}`,
          },
        })
        .catch((error) =>
          Sentry.captureException(error, { extra: { id, callId } }),
        ),
  },
  documentations: {
    get: ({ id, type, apiKey, jwt }: DocumentationRQ) =>
      axios
        .get(
          `${config.services.mediquo.sdk_api_url}/v1/${type}/${id}/documents`,
          {
            headers: {
              'x-api-key': apiKey,
              Authorization: `Bearer ${jwt ?? getSession({ id })}`,
            },
          },
        )
        .catch((error) =>
          Sentry.captureException(error, { extra: { id, type } }),
        ),
    download: ({ id, url, jwt }: DownloadRQ) =>
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${jwt ?? getSession({ id })}`,
          },
          responseType: 'blob',
        })
        .catch((error) =>
          Sentry.captureException(error, { extra: { id, url } }),
        ),
  },
  consultations: {
    get: ({ id, apiKey, jwt }: ConsultationRQ) =>
      axios
        .get(`${config.services.mediquo.sdk_api_url}/v1/consultations/${id}`, {
          headers: {
            'x-api-key': apiKey,
            Authorization: `Bearer ${jwt ?? getSession({ id })}`,
          },
        })
        .catch((error) => Sentry.captureException(error, { extra: { id } })),

    retry: ({ id, apiKey, jwt }: ConsultationRQ) =>
      axios
        .put(
          `${config.services.mediquo.sdk_api_url}/v1/consultations/${id}/retry`,
          {},
          {
            headers: {
              'x-api-key': apiKey,
              Authorization: `Bearer ${jwt ?? getSession({ id })}`,
            },
          },
        )
        .catch((error) => Sentry.captureException(error, { extra: { id } })),
    decline: ({ id, apiKey, jwt }: ConsultationRQ | any) =>
      axios
        .put(
          `${config.services.mediquo.chat_api_url}/consultations/v1/immediate-videocall/${id}/decline`,
          {},
          {
            headers: {
              'x-api-key': apiKey,
              Authorization: `Bearer ${jwt ?? getSession({ id })}`,
            },
          },
        )
        .catch((error) => Sentry.captureException(error, { extra: { id } })),
    triage: ({ id, apiKey, jwt }: ConsultationRQ) =>
      axios
        .get(
          `${config.services.mediquo.chat_api_url}/consultations/v1/immediate-videocall/${id}/triage`,
          {
            headers: {
              'x-api-key': apiKey,
              Authorization: `Bearer ${jwt ?? getSession({ id })}`,
            },
          },
        )
        .catch((error) => Sentry.captureException(error, { extra: { id } })),
    extend: ({ id, apiKey, jwt }: ConsultationRQ) =>
      axios
        .post(
          `${config.services.mediquo.chat_api_url}/consultations/v1/immediate-videocall/${id}/extend`,
          {},
          {
            headers: {
              'x-api-key': apiKey!,
              Authorization: `Bearer ${jwt ?? getSession({ id })}`,
            },
          },
        )
        .catch((error) => error),
    confirm: ({ id, apiKey, jwt }: ConsultationRQ) =>
      axios
        .post(
          `${config.services.mediquo.chat_api_url}/consultations/v1/immediate-videocall/${id}/confirm`,
          {},
          {
            headers: {
              'x-api-key': apiKey!,
              Authorization: `Bearer ${jwt ?? getSession({ id })}`,
            },
          },
        )
        .catch((error) => error),
  },
  waiting: {
    joinWaitingRoom: ({ id, professionalHash, apiKey, jwt }: WaitingRoom) => {
      return axios
        .post(
          `${config.services.mediquo.sdk_api_url}/v1/waiting/${professionalHash}/join`,
          {
            consultation_type: ConsultationType.appointment,
            consultation_id: id,
          },
          {
            headers: {
              'x-api-key': apiKey!,
              Authorization: `Bearer ${jwt ?? getSession({ id })}`,
            },
          },
        )
        .catch((error) =>
          Sentry.captureException(error, { extra: { id, professionalHash } }),
        );
    },
    leaveWaitingRoom: ({ id, professionalHash, apiKey, jwt }: WaitingRoom) => {
      axios
        .post(
          `${config.services.mediquo.sdk_api_url}/v1/waiting/${professionalHash}/leave`,
          {
            consultation_type: ConsultationType.appointment,
            consultation_id: id,
          },
          {
            headers: {
              'x-api-key': apiKey!,
              Authorization: `Bearer ${jwt ?? getSession({ id })}`,
            },
          },
        )
        .catch((error) =>
          Sentry.captureException(error, { extra: { id, professionalHash } }),
        );
    },
    joinerReadyWaitingRoom: ({
      id,
      professionalHash,
      apiKey,
      jwt,
    }: WaitingRoom) => {
      axios
        .post(
          `${config.services.mediquo.sdk_api_url}/v1/waiting/${professionalHash}/ready`,
          {
            consultation_type: ConsultationType.appointment,
            consultation_id: id,
          },
          {
            headers: {
              'x-api-key': apiKey!,
              Authorization: `Bearer ${jwt ?? getSession({ id })}`,
            },
          },
        )
        .catch((error) =>
          Sentry.captureException(error, { extra: { id, professionalHash } }),
        );
    },
    joinerUnreadyWaitingRoom: ({
      id,
      professionalHash,
      apiKey,
      jwt,
    }: WaitingRoom) => {
      axios
        .post(
          `${config.services.mediquo.sdk_api_url}/v1/waiting/${professionalHash}/unready`,
          {
            consultation_type: ConsultationType.appointment,
            consultation_id: id,
          },
          {
            headers: {
              'x-api-key': apiKey!,
              Authorization: `Bearer ${jwt ?? getSession({ id })}`,
            },
          },
        )
        .catch((error) =>
          Sentry.captureException(error, { extra: { id, professionalHash } }),
        );
    },
  },
};
