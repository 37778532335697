import { useAppointment } from '../../containers/Appointment/useAppointment';
import Profile from './Profile';
import Browser from './Browser';
import { AppointmentStates } from '../../utils/states';
import Loading from '../Loading';
import { usePatient } from '../../containers/Appointment/usePatient';
import ErrorView from '../Error';
import { MediquoEvent } from '../../lib/mediquo-events';

const Appointment = () => {
  const { profile, onUpdate } = usePatient();
  const {
    appointment,
    navigateDocumentation,
    error,
  }: { appointment: any; navigateDocumentation: any; error: any } =
    useAppointment();

  if (appointment && profile) {
    switch (appointment.status) {
      case AppointmentStates.finished:
        navigateDocumentation();
        break;
      default:
        return (
          <Browser>
            <Profile
              profile={profile}
              onUpdate={onUpdate}
              appointment={appointment}
            />
          </Browser>
        );
    }
  }
  if (error) {
    window.parent.postMessage(
      { command: MediquoEvent.internal.videocallError },
      '*',
    );
    const state =
      error?.status === 400
        ? 'notAvailable'
        : error?.status === 401
          ? 'expired'
          : 'generic';
    return <ErrorView state={state} />;
  }

  return <Loading />;
};

export default Appointment;
