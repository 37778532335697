import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { MediQuoAPI } from '../../api';
import { config } from '../../config';
import { getTemplate } from '../../utils/useTheme';
import { getSession } from '../../utils/useSession';
import TrackerCall from '../../utils/TrackingCall';
import TrackerSdk from '../../utils/TrackingSdk';
import { usePortal } from '../../utils/usePortal';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { MediquoEvent } from '../../lib/mediquo-events';

export const useAppointment = () => {
  const { id, apiKey, jwt, type } = useParams();
  const navigate = useNavigate();
  const [appointment, setAppointment] = useState();
  const [immediateExpiresAt, setImmediateExpiresAt] = useState();
  const [documents, setDocuments] = useState();
  const [error, setError] = useState();
  const { template } = getTemplate({ apiKey, appointmentId: id });
  const immediateCall = type === 'i';
  const portal = usePortal();
  const [isWidgetReady, setIsWidgetReady] = useState(false);
  const { i18n } = useTranslation();

  useEffect(() => {
    setError(null);
    MediQuoAPI.appointments.get({ id, apiKey, jwt }).then((response) => {
      if (response.status !== 200) {
        return setError(response);
      }
      const { data } = response;
      const appointment = data?.data;

      TrackerCall.setParams({
        consultationId: appointment.id,
        consultationType: 'appointment',
        apiKey,
      });
      TrackerSdk.setParams({
        consultationId: appointment.id,
        consultationType: 'appointment',
        apiKey,
      });

      setAppointment(data?.data);
      const trackerData = {
        consultationId: appointment.id,
        consultationType: 'appointment',
        callId: appointment.call_id,
        userHash: appointment.to.hash,
      };
      setImmediateExpiresAt(dayjs(data?.data?.immediate_video_call_expires_at));
      TrackerCall.setConsultation(trackerData);
      TrackerSdk.setConsultation(trackerData);

      MediQuoAPI.documentations
        .get({ id, type: 'appointments', apiKey, jwt })
        .then(({ data }) => setDocuments(data?.data));

      if (!immediateCall && template?.primary_color) {
        window.MediquoWidget.init({
          apiKey: apiKey,
          theme: {
            locale: i18n.language,
            launcher: 'hidden',
            container: { size: 'full_screen' },
            colors: {
              primary: template?.primary_color,
              secondary: template?.primary_color,
            },
          },
          blockNavigation: true,
          accessToken: getSession({ id }),
          environment:
            config.app.env === 'production' ? 'production' : 'sandbox',
        })
          .catch((err) => {
            console.log(err);
          })
          .then(() => setIsWidgetReady(true));
      }
    });

    return () => {
      setAppointment(undefined);
      setDocuments(undefined);
    };
  }, [template]);

  const navigateDocumentation = () => {
    navigate(`/${type}/${id}/${apiKey}/${jwt}/documentation`);
  };

  const hangUp = () => {
    TrackerCall.event('leave call');

    navigate(`/${type}/${id}/${apiKey}/${jwt}/reporting`);
  };

  const cancelVideoCall = () => {
    if (id && apiKey && jwt)
      MediQuoAPI.consultations.decline({ id, apiKey, jwt }).then(() => {
        navigate(`/${type}/${id}/${apiKey}/${jwt}/declined`);
        window.parent.postMessage(
          { command: MediquoEvent.internal.videocallCanceled },
          '*',
        );
      });
  };

  const extendVideoCall = () => {
    if (id && apiKey && jwt)
      MediQuoAPI.consultations.extend({ id, apiKey, jwt }).then((res) => {
        if (res?.response?.status === 403) {
          portal.close('videocall-professional-unavailable');
          return navigate(`/${type}/${id}/${apiKey}/${jwt}/expired`);
        }
        setImmediateExpiresAt(dayjs(immediateExpiresAt).add(7, 'minute'));
      });
  };

  return {
    appointment,
    documents,
    hangUp,
    navigateDocumentation,
    error,
    cancelVideoCall,
    extendVideoCall,
    isWidgetReady,
    immediateExpiresAt,
  };
};
