import { useTranslation } from 'react-i18next';
import AppLogo from '../assets/images/AppLogo';
import LineSeparator from './LineSeparator';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from './Select';
import { Namespace } from '../i18n/namespaces';
import { useEffect } from 'react';
import { useIsInApp } from '../hooks/useIsInApp';
import { cn } from '../utils/cn';

interface HeaderViewProps {
  className?: string;
  title?: string;
  subtitle?: string;
  isWithLineSeparator: boolean;
  withLanguageSelector?: boolean;
  showLogo?: boolean;
  centered?: boolean;
}

const HeaderView = ({
  title,
  subtitle,
  isWithLineSeparator,
  withLanguageSelector = false,
  showLogo = true,
  centered = false,
  className,
}: HeaderViewProps) => {
  const { t, i18n } = useTranslation(Namespace.Locale);
  const isInApp = useIsInApp();

  useEffect(() => {
    if (isInApp) {
      i18n.changeLanguage('es');
    }
  }, []);

  return (
    <div className={className}>
      {showLogo ? (
        <div className="py-6 mb-3 m-auto sm:ml-0 flex items-center justify-between gap-4">
          <AppLogo center={!withLanguageSelector} />

          {!isInApp && withLanguageSelector ? (
            <div>
              <Select
                defaultValue={i18n.language}
                onValueChange={i18n.changeLanguage}
              >
                <SelectTrigger>
                  <SelectValue />
                </SelectTrigger>
                <SelectContent
                  align="end"
                  sideOffset={4.5}
                  className="min-w-[160px]"
                >
                  <SelectItem value="es">{t('es')}</SelectItem>
                  <SelectItem value="en">{t('en')}</SelectItem>
                  <SelectItem value="pt">{t('pt')}</SelectItem>
                </SelectContent>
              </Select>
            </div>
          ) : null}
        </div>
      ) : (
        <div className="mb-3" />
      )}
      <h1
        className={cn(
          'text-primary text-2xl mb-2 text-center',
          !centered && 'sm:text-start',
        )}
      >
        {title}
      </h1>
      {subtitle ? (
        <p
          className={cn(
            'text-text text-sm mb-6 text-center font-lighter',
            !centered && 'sm:text-start',
          )}
        >
          {subtitle}
        </p>
      ) : null}
      {isWithLineSeparator ? <LineSeparator /> : <div></div>}
    </div>
  );
};

export default HeaderView;
